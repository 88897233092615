import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

function hasKey(object, key) {
    return Object.prototype.hasOwnProperty.call(object, key);
}

function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

function mergeDeep(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }

    return mergeDeep(target, ...sources);
}

function snakeCaseKeys(object, excludedKeys = []) {
    return snakecaseKeys(object, { deep: true, exclude: [...excludedKeys] });
}

function camelCaseKeys(object, excludedKeys = []) {
    return camelcaseKeys(object, { deep: true, exclude: [...excludedKeys] });
}

export { hasKey, isObject, mergeDeep, snakeCaseKeys, camelCaseKeys };
